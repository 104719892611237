import api from "./api-service";
import { stringify } from "querystring";

const url = "tumoral-phenomena";

export const find = (filter) => {
  return api().get(`${url}?${stringify(filter)}`);
};

export const save = (tumoralPhenomenon) => {
  return api().post(url, tumoralPhenomenon);
};

export const findOne = (id) => {
  return api().get(`${url}/${id}`);
};

export const changeOrder = ({ tumoralPhenomenon, direction }) => {
  return api().put(`${url}/order/direction/${direction}`, tumoralPhenomenon);
};
